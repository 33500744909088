@import "./colors.css";

.gaugeChart {
  max-height: 130px;
}

.main_mentor_container {
  margin: 10px;
}

.dashboardCard {
  padding: 10px;
}

.statisticsCard {
  padding: 10px;
  height: 250px;
}

.statisticsCardDetailLink {
  float: right;
  color: var(--primary--color);
}

.gaugeChart .text-group>text {
  font-size: 16px !important;
}

.planCardHeader {
  min-height: 150px;
}

.recommendedHeader {
  background-color: var(--primary--color);
  padding: 5px;
  text-align: center;
  color: white;
  text-transform: capitalize;
}

.planCard:hover {
  border: 1px solid var(--primary--color);
}

.planInfo {
  height: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.planTitle {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  font-family: sans-serif;
}

.planPrice {
  /* text-align: center; */
  padding-left: 15%;
  font-size: 15px;
  font-weight: 300;
}

.recommendedPlanPrice {
  text-align: center;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.strikePrice {
  color: red;
  font-size: 14px;
}

.unitPrice {
  color: #dee2e6;
}

.planDescription {
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
}

.planFeaturesList {
  list-style: none;
  font-size: 14px;
  min-height: 100%;
}

.planFeatures {
  margin-top: 10px;
}

.checkedIcon {
  color: var(--success--color);
}

.timesIcon {
  color: var(--error--color);
}

.planFeaturesLabel {
  padding-left: 5px;
}

.purchasePlanButton {
  float: right;
  font-size: 14px;
  padding: 5px;
  text-align: center;
  width: 100%;
  color: white;
  background-color: var(--primary--color);
  cursor: pointer;
}

.purchasePlanButtonBlocked {
  float: right;
  font-size: 14px;
  padding: 5px;
  text-align: center;
  width: 100%;
  color: white;
  cursor: not-allowed;
  background-color: var(--disabled--color);
}

.filterCard {
  background-color: gray;
  /* padding: 10px; */
  height: 120px;
}

.pdfIcon {
  cursor: pointer;
  float: right;
}

/* style={{ float: "right", cursor: "pointer", fontSize: "1.5rem" }} */

.expiredPlan {
  background-color: gray;
}

.margin_top_row_2 {
  margin-top: 2rem;
}

.padding_bottom_2 {
  padding-bottom: 2rem;
}

.pointer {
  cursor: pointer !important;
}

.quotaIconActionMenu {
  width: 2rem;
  margin-right: 5px;
}

.edit_header_icon_save {
  cursor: pointer;
  margin-right: 15px;
  font-size: 1.5rem;
  color: var(--primary--color)
}

.edit_header_icon_close {
  cursor: pointer;
  font-size: 1.5rem;
  color: grey
}

.edit_header_icon_add {
  cursor: pointer;
  margin-right: 15px;
  vertical-align: sub;
  font-size: 1.5rem;
  color: var(--primary--color)
}

.edit_header_icon_add_blocked {
  cursor: pointer;
  margin-right: 15px;
  vertical-align: sub;
  font-size: 1.5rem;
  color: var(--disabled--color)
}

.floatRight {
  float: right;
}

.selectPaymentMethod {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15%;
  padding-right: 15%;
}

.purchaseButton {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15%;
  padding-right: 15%;
}