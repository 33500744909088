@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

/*! component icons */
.e-datetime-wrapper .e-time-icon.e-icons::before {
  content: '\e20c';
  font-family: 'e-icons';
}

.e-datetime-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-datetime-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 20px;
}

.e-bigger .e-datetime-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-datetime-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-date-icon,
.e-bigger .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-bigger .e-datetime-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-datetime-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-time-icon,
.e-bigger .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 24px;
}

.e-small .e-datetime-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-datetime-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-date-icon,
.e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-small .e-datetime-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-datetime-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-time-icon,
.e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 18px;
}

.e-bigger.e-small .e-outline.e-datetime-wrapper .e-input-group-icon.e-date-icon,
.e-bigger.e-small.e-outline.e-datetime-wrapper .e-input-group-icon.e-date-icon,
.e-datetime-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-date-icon,
.e-bigger.e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-bigger.e-small .e-outline.e-datetime-wrapper .e-input-group-icon.e-time-icon,
.e-bigger.e-small.e-outline.e-datetime-wrapper .e-input-group-icon.e-time-icon,
.e-datetime-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-time-icon,
.e-bigger.e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 20px;
}

.e-datetime-wrapper.e-filled .e-input-group-icon.e-time-icon,
.e-datetime-wrapper.e-control-wrapper.e-filled .e-input-group-icon.e-time-icon,
.e-rtl.e-datetime-wrapper.e-filled .e-input-group-icon.e-time-icon,
.e-rtl.e-datetime-wrapper.e-control-wrapper.e-filled .e-input-group-icon.e-time-icon,
.e-rtl .e-datetime-wrapper.e-filled .e-input-group-icon.e-time-icon,
.e-rtl .e-datetime-wrapper.e-control-wrapper.e-filled .e-input-group-icon.e-time-icon {
  margin: 0;
}

.e-input-group.e-control-wrapper.e-datetime-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
.e-float-input.e-control-wrapper.e-input-group.e-datetime-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
  display: -ms-flexbox;
  display: flex;
}

.e-datetime-wrapper {
  -webkit-tap-highlight-color: transparent;
}

.e-datetime-wrapper .e-time-icon.e-icons::before {
  font-size: 16px;
}

.e-datetime-wrapper.e-control-wrapper {
  box-sizing: border-box;
}

.e-datetime-wrapper .e-time-icon.e-icons.e-disabled,
.e-datetime-wrapper .e-date-icon.e-icons.e-disabled {
  pointer-events: none;
}

.e-datetime-wrapper .e-clear-icon {
  box-sizing: content-box;
}

.e-datetime-wrapper span {
  cursor: pointer;
}

.e-datetime-wrapper .e-input-group-icon.e-date-icon,
.e-datetime-wrapper .e-input-group-icon.e-time-icon {
  font-size: 16px;
  margin: 0 0 4px;
  min-height: 24px;
  min-width: 24px;
  outline: none;
}

.e-datetime-wrapper .e-input-group-icon.e-time-icon {
  border: 0;
  border-style: none;
  margin: 0 0 4px 6px;
}

.e-datetime-wrapper:not(.e-outline).e-rtl .e-input-group-icon.e-time-icon {
  margin: 0 6px 4px 0;
}

.e-datetimepicker.e-time-modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-datetimepicker.e-popup {
  border-style: solid;
  border-width: 1px;
  overflow: auto;
}

.e-datetimepicker.e-popup .e-content {
  position: relative;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul {
  margin: 0;
  padding: 8px 0;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
  font-size: 13px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover {
  cursor: pointer;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  line-height: 36px;
  text-indent: 16px;
}

.e-bigger.e-datetime-wrapper .e-time-icon.e-icons::before,
.e-bigger .e-datetime-wrapper .e-time-icon.e-icons::before {
  font-size: 20px;
}

.e-bigger.e-datetime-wrapper .e-input-group-icon.e-time-icon,
.e-bigger .e-datetime-wrapper .e-input-group-icon.e-time-icon {
  margin: 5px 0 6px 8px;
  min-height: 26px;
  min-width: 26px;
}

.e-bigger.e-datetime-wrapper:not(.e-outline).e-rtl .e-input-group-icon.e-time-icon,
.e-bigger .e-datetime-wrapper:not(.e-outline).e-rtl .e-input-group-icon.e-time-icon {
  margin: 5px 8px 6px 0;
}

.e-bigger .e-datetimepicker.e-popup .e-list-parent.e-ul,
.e-bigger.e-datetimepicker.e-popup .e-list-parent.e-ul {
  padding: 8px 0;
}

.e-bigger .e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-bigger.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 14px;
  line-height: 48px;
  text-indent: 16px;
}

.e-small .e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-small.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 12px;
  line-height: 26px;
  text-indent: 12px;
}

.e-small.e-bigger .e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-small.e-bigger.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 13px;
  line-height: 40px;
  text-indent: 16px;
}

.e-small.e-datetime-wrapper .e-time-icon.e-icons::before,
.e-small .e-datetime-wrapper .e-time-icon.e-icons::before {
  font-size: 14px;
}

.e-small.e-bigger.e-datetime-wrapper .e-time-icon.e-icons::before,
.e-small.e-bigger .e-datetime-wrapper .e-time-icon.e-icons::before {
  font-size: 18px;
}

.e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker {
  background-size: 250px 33px;
  min-height: 33px;
}

.e-bigger .e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker,
.e-bigger.e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker {
  background-size: 250px 40px;
  min-height: 40px;
}

/*! datetimepicker theme */
.e-datetime-wrapper .e-input-group-icon.e-icons.e-active {
  color: #007bff;
}

.e-datetime-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: #007bff;
}

.e-datetimepicker.e-popup {
  border: none;
  border-radius: 2px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.e-datetimepicker.e-popup .e-list-parent.e-ul {
  background-color: #fff;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul li.e-list-item {
  border: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover,
.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-navigation,
.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item:focus {
  background-color: #eee;
  border: none;
  color: #000;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #eee;
  color: #007bff;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #eee;
  color: #007bff;
}
