@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

/*! component icons */
.e-time-wrapper .e-time-icon.e-icons::before,
.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons::before {
  content: '\e20c';
}

.e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 20px;
}

.e-bigger .e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-time-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-time-icon,
.e-bigger .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 24px;
}

.e-small .e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-time-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-time-icon,
.e-small .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 18px;
}

.e-bigger.e-small .e-outline.e-time-wrapper .e-input-group-icon.e-time-icon,
.e-bigger.e-small.e-outline.e-time-wrapper .e-input-group-icon.e-time-icon,
.e-time-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-time-icon,
.e-bigger.e-small .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 20px;
}

.e-input-group.e-control-wrapper.e-time-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
.e-float-input.e-control-wrapper.e-input-group.e-time-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
  display: -ms-flexbox;
  display: flex;
}

.e-time-wrapper,
.e-control-wrapper.e-time-wrapper {
  -webkit-tap-highlight-color: transparent;
}

.e-time-wrapper .e-time-icon.e-icons,
.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons {
  font-size: 16px;
  min-height: 20px;
  min-width: 20px;
}

.e-time-wrapper .e-time-icon.e-icons.e-disabled,
.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons.e-disabled {
  pointer-events: none;
}

.e-time-wrapper span,
.e-control-wrapper.e-time-wrapper span {
  cursor: pointer;
}

.e-timepicker.e-time-modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-timepicker.e-popup {
  border-style: solid;
  border-width: 1px;
  overflow: auto;
}

.e-timepicker.e-popup .e-content {
  position: relative;
}

.e-timepicker.e-popup .e-list-parent.e-ul {
  margin: 0;
  padding: 0 0;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  cursor: default;
  font-size: 13px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover {
  cursor: pointer;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  line-height: 36px;
  text-indent: 16px;
}

.e-bigger .e-time-wrapper .e-time-icon.e-icons,
.e-bigger.e-time-wrapper .e-time-icon.e-icons,
.e-bigger .e-control-wrapper .e-time-wrapper .e-time-icon.e-icons,
.e-control-wrapper.e-bigger.e-time-wrapper .e-time-icon.e-icons {
  font-size: 20px;
  min-height: 22px;
  min-width: 22px;
}

.e-bigger .e-timepicker.e-popup .e-list-parent.e-ul,
.e-bigger.e-timepicker.e-popup .e-list-parent.e-ul {
  padding: 0 0;
}

.e-bigger .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-bigger.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 14px;
  line-height: 48px;
  text-indent: 16px;
}

.e-small .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-small.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 12px;
  line-height: 26px;
  text-indent: 12px;
}

.e-small .e-time-wrapper .e-time-icon.e-icons,
.e-small.e-time-wrapper .e-time-icon.e-icons,
.e-small .e-control-wrapper.e-time-wrapper .e-time-icon.e-icons,
.e-control-wrapper.e-small.e-time-wrapper .e-time-icon.e-icons {
  font-size: 14px;
}

.e-small.e-bigger .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-small.e-bigger.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 13px;
  line-height: 40px;
  text-indent: 16px;
}

.e-small.e-bigger .e-time-wrapper .e-time-icon.e-icons,
.e-small.e-bigger.e-time-wrapper .e-time-icon.e-icons,
.e-small.bigger .e-control-wrapper.e-time-wrapper .e-time-icon.e-icons,
.e-control-wrapper.e-small.bigger.e-time-wrapper .e-time-icon.e-icons {
  font-size: 18px;
}

.e-content-placeholder.e-timepicker.e-placeholder-timepicker {
  background-size: 250px 33px;
  min-height: 33px;
}

.e-bigger .e-content-placeholder.e-timepicker.e-placeholder-timepicker,
.e-bigger.e-content-placeholder.e-timepicker.e-placeholder-timepicker {
  background-size: 250px 40px;
  min-height: 40px;
}

.e-time-overflow {
  overflow: hidden;
}

.e-timepicker-mob-popup-wrap {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  -ms-flex-pack: center;
      justify-content: center;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;
}

.e-timepicker-mob-popup-wrap .e-timepicker.e-popup.e-lib.e-control.e-popup-open {
  left: 0 !important;
  position: relative;
  top: 0 !important;
}

/*! timepicker theme */
.e-time-wrapper .e-input-group-icon.e-icons.e-active {
  color: #007bff;
}

.e-time-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: #007bff;
}

.e-timepicker.e-popup {
  border: none;
  border-radius: 2px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.e-timepicker.e-popup .e-list-parent.e-ul {
  background-color: #fff;
}

.e-timepicker.e-popup .e-list-parent.e-ul li.e-list-item {
  border: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-disabled {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover,
.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-navigation,
.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item:focus {
  background-color: #eee;
  border: none;
  color: #000;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #eee;
  color: #007bff;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #eee;
  color: #007bff;
}

.e-small .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-small.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-bigger.e-small .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-bigger.e-small.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  color: #000;
}
