@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

/*! calendar material theme variables */
/*! component icons */
.e-input-group-icon.e-date-icon,
.e-control-wrapper .e-input-group-icon.e-date-icon {
  font-size: 16px;
  margin: 0 0 4px 0;
  min-height: 24px;
  min-width: 24px;
  outline: none;
}

.e-input-group-icon.e-date-icon::before,
.e-control-wrapper .e-input-group-icon.e-date-icon::before {
  content: '\e901';
  font-family: 'e-icons';
}

.e-input-group-icon.e-date-icon:focus,
.e-control-wrapper .e-input-group-icon.e-date-icon:focus {
  background: #eee;
  border-radius: 50%;
}

.e-bigger .e-input-group-icon.e-date-icon,
.e-control-wrapper.e-bigger .e-input-group-icon.e-date-icon,
.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon {
  font-size: 20px;
  margin: 5px 0 6px 0;
  min-height: 26px;
  min-width: 26px;
}

.e-bigger .e-input-group-icon.e-date-icon::before,
.e-control-wrapper.e-bigger .e-input-group-icon.e-date-icon::before,
.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon::before {
  content: '\e901';
  font-family: 'e-icons';
}

.e-bigger .e-input-group-icon.e-date-icon:focus,
.e-control-wrapper.e-bigger .e-input-group-icon.e-date-icon:focus,
.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon:focus {
  background: #eee;
  border-radius: 50%;
}

.e-small .e-input-group-icon.e-date-icon,
.e-control-wrapper.e-small .e-input-group-icon.e-date-icon,
.e-small .e-control-wrapper .e-input-group-icon.e-date-icon {
  font-size: 14px;
}

.e-small.e-bigger .e-input-group-icon.e-date-icon,
.e-control-wrapper.e-small.e-bigger .e-input-group-icon.e-date-icon,
.e-small.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon {
  font-size: 18px;
}

.e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
  min-height: 0;
  min-width: 20px;
}

.e-bigger .e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-date-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-date-icon,
.e-bigger .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
  min-height: 0;
  min-width: 24px;
}

.e-small .e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-date-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-date-icon,
.e-small .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
  min-height: 0;
  min-width: 18px;
}

.e-bigger.e-small .e-outline.e-date-wrapper .e-input-group-icon.e-date-icon,
.e-bigger.e-small.e-outline.e-date-wrapper .e-input-group-icon.e-date-icon,
.e-date-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-date-icon,
.e-bigger.e-small .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
  min-height: 0;
  min-width: 20px;
}

.e-input-group.e-control-wrapper.e-date-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
.e-float-input.e-control-wrapper.e-input-group.e-date-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
  display: -ms-flexbox;
  display: flex;
}

.e-date-wrapper:not(.e-filled) .e-date-icon.e-icons {
  box-sizing: border-box;
}

.e-datepicker .e-calendar .e-content table tbody tr.e-month-hide:last-child {
  display: none;
}

.e-datepicker.e-popup-wrapper {
  border-radius: 0;
  overflow-y: hidden;
  pointer-events: auto;
}

.e-datepicker.e-date-modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-datepicker .e-model-header {
  background-color: #007bff;
  color: #fff;
  cursor: default;
  display: block;
  padding: 10px 10px 10px 15px;
}

.e-datepicker .e-model-header .e-model-year {
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
}

.e-datepicker .e-model-month,
.e-datepicker .e-model-day {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
}

.e-date-overflow {
  overflow: hidden !important;
}

.e-datepick-mob-popup-wrap {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  -ms-flex-pack: center;
      justify-content: center;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;
}

.e-datepick-mob-popup-wrap .e-datepicker.e-popup-wrapper.e-lib.e-popup.e-control.e-popup-open {
  position: relative;
  top: 0 !important;
  left: 0 !important;
}

.e-content-placeholder.e-datepicker.e-placeholder-datepicker {
  background-size: 250px 33px;
  min-height: 33px;
}

.e-bigger .e-content-placeholder.e-datepicker.e-placeholder-datepicker,
.e-bigger.e-content-placeholder.e-datepicker.e-placeholder-datepicker {
  background-size: 250px 40px;
  min-height: 40px;
}

@media screen and (orientation: landscape) and (max-height: 360px) {
  .e-datepicker .e-calendar.e-device .e-month table tbody {
    display: inline-block;
    height: 130px;
    overflow: auto;
  }
}

.e-datepicker.e-popup-wrapper,
.e-bigger.e-small .e-datepicker.e-popup-wrapper {
  border: none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.e-datepicker .e-calendar,
.e-bigger.e-small .e-datepicker .e-calendar {
  background-color: #fff;
  border: none;
}

.e-input-group.e-date-wrapper.e-dateinput-active:active:not(.e-success):not(.e-warning):not(.e-error) {
  border: none;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: #007bff;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: #007bff;
}
