@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");

/*! calendar material theme variables */
/*! component icons */
.e-calendar .e-header .e-date-icon-prev::before {
  content: '\e910';
}

.e-calendar .e-header .e-date-icon-next::before {
  content: '\e916';
}

/*! calendar layout */
ejs-calendar {
  display: block;
}

.e-calendar,
.e-bigger.e-small .e-calendar {
  -webkit-tap-highlight-color: transparent;
  border-radius: 2px;
  display: block;
  overflow: auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.e-calendar.e-rtl .e-header .e-title,
.e-bigger.e-small .e-calendar.e-rtl .e-header .e-title {
  float: right;
  text-align: right;
}

.e-calendar.e-rtl .e-header .e-icon-container,
.e-bigger.e-small .e-calendar.e-rtl .e-header .e-icon-container {
  float: left;
}

.e-calendar .e-header,
.e-bigger.e-small .e-calendar .e-header {
  background: none;
  display: table;
  font-weight: 500;
  position: relative;
  text-align: center;
  width: 100%;
}

.e-calendar .e-header button,
.e-bigger.e-small .e-calendar .e-header button {
  background: transparent;
  border: 0;
  padding: 0;
  text-decoration: none;
}

.e-calendar .e-header span,
.e-bigger.e-small .e-calendar .e-header span {
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  padding: 10px;
  vertical-align: middle;
}

.e-calendar .e-header span.e-disabled,
.e-bigger.e-small .e-calendar .e-header span.e-disabled {
  cursor: default;
}

.e-calendar .e-week-header,
.e-bigger.e-small .e-calendar .e-week-header {
  padding: 0;
}

.e-calendar th,
.e-bigger.e-small .e-calendar th {
  cursor: default;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
}

.e-calendar .e-content .e-selected,
.e-calendar .e-content .e-state-hover,
.e-bigger.e-small .e-calendar .e-content .e-selected,
.e-bigger.e-small .e-calendar .e-content .e-state-hover {
  border-radius: 0;
}

.e-calendar .e-content span.e-day,
.e-bigger.e-small .e-calendar .e-content span.e-day {
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}

.e-calendar .e-content th,
.e-calendar .e-content td,
.e-bigger.e-small .e-calendar .e-content th,
.e-bigger.e-small .e-calendar .e-content td {
  box-sizing: border-box;
}

.e-calendar .e-content td.e-disabled,
.e-bigger.e-small .e-calendar .e-content td.e-disabled {
  opacity: 0.35;
  pointer-events: none;
  -ms-touch-action: none;
  touch-action: none;
}

.e-calendar .e-content td,
.e-bigger.e-small .e-calendar .e-content td {
  cursor: pointer;
  padding: 2px;
  text-align: center;
}

.e-calendar .e-content td.e-week-number,
.e-bigger.e-small .e-calendar .e-content td.e-week-number {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
}

.e-calendar .e-content td.e-overlay,
.e-bigger.e-small .e-calendar .e-content td.e-overlay {
  background: none;
  width: initial;
}

.e-calendar .e-content table,
.e-bigger.e-small .e-calendar .e-content table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 0;
  float: left;
  margin: 0;
  outline: 0;
  padding: 0 10px 10px;
  table-layout: fixed;
  width: 100%;
}

.e-calendar .e-content td.e-other-month>span.e-day,
.e-calendar .e-content td.e-other-year>span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-other-month>span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-other-year>span.e-day {
  display: none;
  font-weight: normal;
}

.e-calendar .e-content tr.e-month-hide,
.e-bigger.e-small .e-calendar .e-content tr.e-month-hide {
  display: none;
  font-weight: normal;
}

.e-calendar .e-content tr.e-month-hide,
.e-calendar .e-content td.e-other-month,
.e-calendar .e-content td.e-other-year,
.e-bigger.e-small .e-calendar .e-content tr.e-month-hide,
.e-bigger.e-small .e-calendar .e-content td.e-other-month,
.e-bigger.e-small .e-calendar .e-content td.e-other-year {
  pointer-events: none;
  -ms-touch-action: none;
  touch-action: none;
}

.e-calendar .e-content tr.e-month-hide,
.e-calendar .e-content td.e-other-month.e-disabled,
.e-calendar .e-content td.e-other-year.e-disabled,
.e-bigger.e-small .e-calendar .e-content tr.e-month-hide,
.e-bigger.e-small .e-calendar .e-content td.e-other-month.e-disabled,
.e-bigger.e-small .e-calendar .e-content td.e-other-year.e-disabled {
  pointer-events: none;
  -ms-touch-action: none;
  touch-action: none;
}

.e-calendar .e-content td.e-week-number:hover span.e-day,
.e-calendar .e-content td.e-week-number:hover,
.e-bigger.e-small .e-calendar .e-content td.e-week-number:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-week-number:hover {
  background-color: #fff;
  cursor: default;
}

.e-calendar .e-header .e-prev,
.e-calendar .e-header .e-next,
.e-bigger.e-small .e-calendar .e-header .e-prev,
.e-bigger.e-small .e-calendar .e-header .e-next {
  border-radius: 50%;
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title {
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title {
  margin-left: 5px;
}

.e-calendar .e-header .e-prev:hover,
.e-calendar .e-header .e-next:hover,
.e-bigger.e-small .e-calendar .e-header .e-prev:hover,
.e-bigger.e-small .e-calendar .e-header .e-next:hover {
  cursor: pointer;
}

.e-calendar .e-header .e-prev.e-overlay,
.e-calendar .e-header .e-next.e-overlay,
.e-bigger.e-small .e-calendar .e-header .e-prev.e-overlay,
.e-bigger.e-small .e-calendar .e-header .e-next.e-overlay {
  background: none;
}

.e-calendar .e-header.e-decade .e-title,
.e-calendar .e-header.e-year .e-title,
.e-bigger.e-small .e-calendar .e-header.e-decade .e-title,
.e-bigger.e-small .e-calendar .e-header.e-year .e-title {
  margin-left: 5px;
}

.e-calendar .e-header.e-decade .e-title,
.e-bigger.e-small .e-calendar .e-header.e-decade .e-title {
  cursor: default;
}

.e-calendar .e-header .e-icon-container,
.e-bigger.e-small .e-calendar .e-header .e-icon-container {
  display: inline-block;
  float: right;
}

.e-calendar .e-footer-container,
.e-bigger.e-small .e-calendar .e-footer-container {
  text-transform: uppercase;
}

.e-calendar,
.e-bigger.e-small .e-calendar {
  max-width: 246px;
  min-width: 240px;
  padding: 0;
}

.e-calendar.e-calendar-day-header-lg,
.e-bigger.e-small .e-calendar.e-calendar-day-header-lg {
  max-width: 100%;
  min-width: 540px;
}

.e-calendar.e-week-number,
.e-bigger.e-small .e-calendar.e-week-number {
  min-width: 262px;
}

.e-calendar.e-week,
.e-bigger.e-small .e-calendar.e-week {
  max-width: 246px;
  min-width: 240px;
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title {
  line-height: 40px;
}

.e-calendar.e-rtl .e-header .e-title,
.e-bigger.e-small .e-calendar.e-rtl .e-header .e-title {
  text-align: right;
  text-indent: 4px;
}

.e-calendar .e-header,
.e-bigger.e-small .e-calendar .e-header {
  height: 40px;
}

.e-calendar .e-header.e-month,
.e-bigger.e-small .e-calendar .e-header.e-month {
  padding: 10px 10px 0 10px;
}

.e-calendar .e-header.e-year,
.e-calendar .e-header.e-decade,
.e-bigger.e-small .e-calendar .e-header.e-year,
.e-bigger.e-small .e-calendar .e-header.e-decade {
  padding: 10px 10px 0 10px;
}

.e-calendar th,
.e-bigger.e-small .e-calendar th {
  font-weight: normal;
  height: 30px;
}

.e-calendar .e-content .e-selected,
.e-calendar .e-content .e-state-hover,
.e-bigger.e-small .e-calendar .e-content .e-selected,
.e-bigger.e-small .e-calendar .e-content .e-state-hover {
  border-radius: 0;
}

.e-calendar .e-content span.e-day,
.e-bigger.e-small .e-calendar .e-content span.e-day {
  border: none;
  font-size: 13px;
  font-weight: normal;
  height: 30px;
  line-height: 30px;
  width: 30px;
}

.e-calendar .e-content.e-year table,
.e-calendar .e-content.e-decade table,
.e-bigger.e-small .e-calendar .e-content.e-year table,
.e-bigger.e-small .e-calendar .e-content.e-decade table {
  border-spacing: 0;
  padding: 10px;
}

.e-calendar .e-content.e-month td,
.e-bigger.e-small .e-calendar .e-content.e-month td {
  height: 30px;
  padding: 2px;
}

.e-calendar .e-content .tfooter>tr>td,
.e-bigger.e-small .e-calendar .e-content .tfooter>tr>td {
  height: 36px;
  line-height: 36px;
}

.e-calendar .e-content.e-year td,
.e-calendar .e-content.e-decade td,
.e-bigger.e-small .e-calendar .e-content.e-year td,
.e-bigger.e-small .e-calendar .e-content.e-decade td {
  height: 45px;
  padding: 6px;
}

.e-calendar .e-content.e-year td>span.e-day,
.e-calendar .e-content.e-decade td>span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td>span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td>span.e-day {
  font-weight: normal;
  height: 45px;
  line-height: 45px;
  width: 45px;
}

.e-calendar .e-header .e-icon-container .e-prev,
.e-calendar .e-header .e-icon-container .e-next,
.e-bigger.e-small .e-calendar .e-header .e-icon-container .e-prev,
.e-bigger.e-small .e-calendar .e-header .e-icon-container .e-next {
  height: 36px;
  width: 36px;
}

.e-calendar .e-footer-container,
.e-bigger.e-small .e-calendar .e-footer-container {
  border-top: none;
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0 10px 10px 10px;
  text-align: center;
  width: 100%;
}

.e-small.e-bigger.e-calendar .e-content.e-year span.e-day,
.e-small.e-bigger .e-calendar .e-content.e-year span.e-day {
  font-size: 13px;
}

.e-small.e-bigger.e-calendar .e-content.e-month table,
.e-small.e-bigger .e-calendar .e-content.e-month table {
  padding: 0 10px 10px;
}

.e-bigger.e-calendar,
.e-bigger .e-calendar {
  max-width: 296px;
  min-width: 296px;
  padding: 0;
}

.e-bigger.e-calendar.e-calendar-day-header-lg,
.e-bigger .e-calendar.e-calendar-day-header-lg {
  max-width: 100%;
  min-width: 540px;
}

.e-bigger.e-calendar.e-week,
.e-bigger .e-calendar.e-week {
  max-width: 296px;
  min-width: 296px;
}

.e-bigger.e-calendar.e-week-number,
.e-bigger .e-calendar.e-week-number {
  min-width: 320px;
}

.e-bigger.e-calendar .e-header .e-title,
.e-bigger .e-calendar .e-header .e-title {
  font-size: 16px;
  line-height: 48px;
  width: 60%;
}

.e-bigger.e-calendar.e-rtl .e-header .e-title,
.e-bigger .e-calendar.e-rtl .e-header .e-title {
  line-height: 48px;
  text-indent: 6px;
}

.e-bigger.e-calendar .e-header,
.e-bigger .e-calendar .e-header {
  height: 48px;
  padding: 10px 10px 0 16px;
}

.e-bigger.e-calendar .e-header span,
.e-bigger .e-calendar .e-header span {
  font-size: 16px;
  padding: 15px;
}

.e-bigger.e-calendar .e-header.e-year,
.e-bigger.e-calendar .e-header.e-decade,
.e-bigger .e-calendar .e-header.e-year,
.e-bigger .e-calendar .e-header.e-decade {
  padding: 10px 10px 0 16px;
}

.e-bigger.e-calendar th,
.e-bigger .e-calendar th {
  font-size: 14px;
  height: 36px;
}

.e-bigger.e-calendar .e-content.e-year span.e-day,
.e-bigger .e-calendar .e-content.e-year span.e-day {
  font-size: 13px;
  font-weight: normal;
}

.e-bigger.e-calendar .e-content.e-month table,
.e-bigger .e-calendar .e-content.e-month table {
  padding: 0 10px 10px;
}

.e-bigger.e-calendar .e-content.e-year table,
.e-bigger.e-calendar .e-content.e-decade table,
.e-bigger .e-calendar .e-content.e-year table,
.e-bigger .e-calendar .e-content.e-decade table {
  padding: 0 10px 10px;
}

.e-bigger.e-calendar .e-content .e-selected,
.e-bigger.e-calendar .e-content .e-state-hover,
.e-bigger .e-calendar .e-content .e-selected,
.e-bigger .e-calendar .e-content .e-state-hover {
  border-radius: 0;
}

.e-bigger.e-calendar .e-content span.e-day,
.e-bigger .e-calendar .e-content span.e-day {
  font-size: 13px;
  height: 36px;
  line-height: 36px;
  width: 36px;
}

.e-bigger.e-calendar .e-content.e-month td,
.e-bigger .e-calendar .e-content.e-month td {
  height: 2px;
  padding: 2px;
}

.e-bigger.e-calendar .e-content.e-year td,
.e-bigger.e-calendar .e-content.e-decade td,
.e-bigger .e-calendar .e-content.e-year td,
.e-bigger .e-calendar .e-content.e-decade td {
  height: 60px;
  padding: 2px;
}

.e-bigger.e-calendar .e-content.e-year td>span.e-day,
.e-bigger.e-calendar .e-content.e-decade td>span.e-day,
.e-bigger .e-calendar .e-content.e-year td>span.e-day,
.e-bigger .e-calendar .e-content.e-decade td>span.e-day {
  height: 60px;
  line-height: 60px;
  width: 60px;
}

.e-bigger.e-calendar .e-header .e-icon-container .e-prev,
.e-bigger.e-calendar .e-header .e-icon-container .e-next,
.e-bigger .e-calendar .e-header .e-icon-container .e-prev,
.e-bigger .e-calendar .e-header .e-icon-container .e-next {
  height: 48px;
  width: 48px;
}

.e-bigger.e-calendar .e-footer-container,
.e-bigger .e-calendar .e-footer-container {
  border-top: none;
  padding: 0 10px 10px 10px;
}

.e-small.e-calendar,
.e-small .e-calendar {
  max-width: 246px;
  min-width: 240px;
  padding: 0;
}

.e-small.e-calendar.e-calendar-day-header-lg,
.e-small .e-calendar.e-calendar-day-header-lg {
  max-width: 100%;
  min-width: 540px;
}

.e-small.e-calendar .e-content span.e-day,
.e-small .e-calendar .e-content span.e-day {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  width: 24px;
}

.e-small.e-calendar .e-content.e-month td,
.e-small .e-calendar .e-content.e-month td {
  height: 24px;
}

.e-small.e-calendar .e-header,
.e-small .e-calendar .e-header {
  height: 32px;
}

.e-small.e-calendar .e-header span,
.e-small .e-calendar .e-header span {
  font-size: 12px;
  padding: 6px;
}

.e-small.e-calendar .e-header .e-title,
.e-small .e-calendar .e-header .e-title {
  font-size: 13px;
  line-height: 32px;
}

.e-small.e-calendar .e-header .e-icon-container .e-prev,
.e-small.e-calendar .e-header .e-icon-container .e-next,
.e-small .e-calendar .e-header .e-icon-container .e-prev,
.e-small .e-calendar .e-header .e-icon-container .e-next {
  height: 28px;
  width: 28px;
}

.e-small.e-calendar th,
.e-small .e-calendar th {
  font-size: 12px;
  height: 24px;
}

.e-calendar .e-btn.e-today.e-flat.e-disabled,
.e-calendar .e-btn.e-today.e-flat.e-disabled:hover,
.e-calendar .e-btn.e-today.e-flat.e-disabled:active,
.e-calendar .e-btn.e-today.e-flat.e-disabled:focus,
.e-calendar .e-btn.e-today.e-flat.e-disabled:hover:active {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
  cursor: default;
  opacity: 0.35;
  outline: none;
  pointer-events: none;
  -ms-touch-action: none;
  touch-action: none;
}

.e-content-placeholder.e-calendar.e-placeholder-calendar {
  background-size: 250px 336px;
  min-height: 336px;
}

.e-bigger .e-content-placeholder.e-calendar.e-placeholder-calendar,
.e-bigger.e-content-placeholder.e-calendar.e-placeholder-calendar {
  background-size: 300px 392px;
  min-height: 392px;
}

.e-calendar,
.e-bigger.e-small .e-calendar {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
}

.e-calendar .e-date-icon-prev,
.e-calendar .e-date-icon-next,
.e-bigger.e-small .e-calendar .e-date-icon-prev,
.e-bigger.e-small .e-calendar .e-date-icon-next {
  color: rgba(0, 0, 0, 0.54);
}

.e-calendar th,
.e-bigger.e-small .e-calendar th {
  border-bottom: 0;
  color: rgba(0, 0, 0, 0.54);
}

.e-calendar .e-header,
.e-bigger.e-small .e-calendar .e-header {
  border-bottom: 0;
}

.e-calendar .e-header a span,
.e-bigger.e-small .e-calendar .e-header a span {
  border: none;
  color: rgba(0, 0, 0, 0.75);
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title {
  color: rgba(0, 0, 0, 0.75);
}

.e-calendar .e-header .e-title:hover,
.e-bigger.e-small .e-calendar .e-header .e-title:hover {
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  text-decoration: none;
}

.e-calendar .e-header .e-prev:hover>span,
.e-calendar .e-header .e-next:hover>span,
.e-bigger.e-small .e-calendar .e-header .e-prev:hover>span,
.e-bigger.e-small .e-calendar .e-header .e-next:hover>span {
  border: none;
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.e-calendar .e-header .e-prev:hover,
.e-calendar .e-header .e-next:hover,
.e-bigger.e-small .e-calendar .e-header .e-prev:hover,
.e-bigger.e-small .e-calendar .e-header .e-next:hover {
  background: none;
}

.e-calendar .e-header .e-prev:active,
.e-calendar .e-header .e-next:active,
.e-bigger.e-small .e-calendar .e-header .e-prev:active,
.e-bigger.e-small .e-calendar .e-header .e-next:active {
  background: #fff;
  color: #fff;
}

.e-calendar .e-header button.e-prev:active span,
.e-calendar .e-header button.e-next:active span,
.e-bigger.e-small .e-calendar .e-header button.e-prev:active span,
.e-bigger.e-small .e-calendar .e-header button.e-next:active span {
  border: none;
  color: rgba(0, 0, 0, 0.75);
}

.e-calendar .e-header.e-decade .e-title,
.e-bigger.e-small .e-calendar .e-header.e-decade .e-title {
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
}

.e-calendar .e-header .e-next.e-disabled span,
.e-calendar .e-header .e-prev.e-disabled span,
.e-bigger.e-small .e-calendar .e-header .e-next.e-disabled span,
.e-bigger.e-small .e-calendar .e-header .e-prev.e-disabled span {
  color: rgba(0, 0, 0, 0.87);
  font-weight: normal;
}

.e-calendar .e-header .e-next.e-disabled,
.e-calendar .e-header .e-prev.e-disabled,
.e-bigger.e-small .e-calendar .e-header .e-next.e-disabled,
.e-bigger.e-small .e-calendar .e-header .e-prev.e-disabled {
  opacity: 0.35;
}

.e-calendar .e-content.e-decade tr:first-child .e-cell:first-child span.e-day,
.e-calendar .e-content.e-decade tr:last-child .e-cell:last-child span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:first-child .e-cell:first-child span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:last-child .e-cell:last-child span.e-day {
  color: rgba(0, 0, 0, 0.38);
}

.e-calendar .e-content.e-decade tr:first-child .e-cell:first-child.e-selected span.e-day,
.e-calendar .e-content.e-decade tr:last-child .e-cell:last-child.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:first-child .e-cell:first-child.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:last-child .e-cell:last-child.e-selected span.e-day {
  color: #fff;
}

.e-calendar .e-content.e-decade tr:first-child .e-cell.e-disabled:first-child span.e-day,
.e-calendar .e-content.e-decade tr:last-child .e-cell.e-disabled:last-child span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:first-child .e-cell.e-disabled:first-child span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:last-child .e-cell.e-disabled:last-child span.e-day {
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content.e-year td:hover span.e-day,
.e-calendar .e-content.e-decade td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td:hover span.e-day {
  background-color: #eee;
}

.e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day {
  background-color: #007bff;
}

.e-calendar .e-content.e-year td>span.e-day,
.e-calendar .e-content.e-decade td>span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td>span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td>span.e-day {
  background: #fff;
}

.e-calendar .e-content .e-week-number span,
.e-bigger.e-small .e-calendar .e-content .e-week-number span {
  color: rgba(0, 0, 0, 0.54);
}

.e-calendar .e-content td.e-focused-date span.e-day,
.e-calendar .e-content td.e-focused-date:hover span.e-day,
.e-calendar .e-content td.e-focused-date:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date:focus span.e-day {
  background: #eee;
  border: none;
  border-radius: 50%;
}

.e-calendar .e-content td.e-focused-date:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date:hover span.e-day {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: none;
  border: 1px solid #007bff;
  border-radius: 50%;
  color: #007bff;
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: #eee;
  border: 1px solid #007bff;
  color: #007bff;
}

.e-calendar .e-content td.e-today:focus span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  color: #007bff;
}

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  background-color: #eee;
  border: 1px solid #007bff;
  color: #007bff;
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
  background-color: #007bff;
  border: 1px solid #007bff;
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff;
}

.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: #b51148;
  color: #fff;
}

.e-calendar .e-content span,
.e-bigger.e-small .e-calendar .e-content span {
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content .e-disabled span.e-day:hover,
.e-bigger.e-small .e-calendar .e-content .e-disabled span.e-day:hover {
  background: none;
  border: 0;
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content .e-other-month:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content .e-other-month:hover span.e-day {
  color: rgba(0, 0, 0, 0.38);
}

.e-calendar .e-content .e-other-month span.e-day,
.e-calendar .e-content .e-other-month.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content .e-other-month span.e-day,
.e-bigger.e-small .e-calendar .e-content .e-other-month.e-today span.e-day {
  color: rgba(0, 0, 0, 0.38);
}

.e-calendar .e-content .e-other-month.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content .e-other-month.e-today:hover span.e-day {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.38);
}

.e-calendar .e-content thead,
.e-bigger.e-small .e-calendar .e-content thead {
  background: none;
  border-bottom: 0;
}

.e-calendar .e-content td:hover span.e-day,
.e-calendar .e-content td:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td:focus span.e-day {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content td:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td:focus span.e-day {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content td.e-disabled span.e-day,
.e-calendar .e-content td.e-disabled:hover span.e-day,
.e-calendar .e-content td.e-disabled:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-disabled span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-disabled:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-disabled:focus span.e-day {
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  color: #fff;
}

.e-calendar .e-content .e-footer,
.e-bigger.e-small .e-calendar .e-content .e-footer {
  color: #007bff;
}

.e-calendar.e-device .e-prev:hover,
.e-calendar.e-device .e-next:hover,
.e-calendar.e-device .e-prev:active,
.e-calendar.e-device .e-next:active,
.e-calendar.e-device .e-prev:focus,
.e-calendar.e-device .e-next:focus,
.e-bigger.e-small .e-calendar.e-device .e-prev:hover,
.e-bigger.e-small .e-calendar.e-device .e-next:hover,
.e-bigger.e-small .e-calendar.e-device .e-prev:active,
.e-bigger.e-small .e-calendar.e-device .e-next:active,
.e-bigger.e-small .e-calendar.e-device .e-prev:focus,
.e-bigger.e-small .e-calendar.e-device .e-next:focus {
  background: none;
}

.e-calendar.e-device button.e-prev:active span,
.e-calendar.e-device button.e-next:active span,
.e-bigger.e-small .e-calendar.e-device button.e-prev:active span,
.e-bigger.e-small .e-calendar.e-device button.e-next:active span {
  color: rgba(0, 0, 0, 0.54);
}

.e-small.e-calendar .e-header .e-title,
.e-small .e-calendar .e-header .e-title {
  color: rgba(0, 0, 0, 0.87);
}

.e-zoomin {
  animation: animatezoom .3s;
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
